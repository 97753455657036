.solidArrow {
  display: inline-block;
  border: 7px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  transition: all 0.2s ease;
}
.lineArrowWrapper {
  position: relative;
}
.lineArrowWrapper .lineArrow:after,
.lineArrowWrapper .lineArrow:before {
  border: 10px solid transparent;
  border-left: 10px solid #f4f4f4;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: -20px;
  content: ' ';
}
.lineArrowWrapper .lineArrow:before {
  border-left-color: #888888;
  right: -22px;
}
