* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  width: 100%;
  min-width: 1200px;
  font-family: PingFangSC, HYZhengYuan-HEW, sans-serif, Arial, 'Microsoft Sans Serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: auto;
  /*overflow-x: hidden;*/
}
ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
input,
button {
  outline: none;
}
img {
  width: 100%;
  height: 100%;
  -webkit-user-drag: none;
}
@font-face {
  font-family: HYZhengYuan-EEW;
  src: url("./assets/fontmin/HYZhengYuan-EEW.ttf") format("truetype");
  /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: HYZhengYuan-FEW;
  src: url("./assets/fontmin/HYZhengYuan-FEW.ttf") format("truetype");
  /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: HYZhengYuan-GEW;
  src: url("./assets/fontmin/HYZhengYuan-GEW.ttf") format("truetype");
  /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: HYZhengYuan-HEW;
  src: url("./assets/fontmin/HYZhengYuan-HEW.ttf") format("truetype");
  /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  font-weight: normal;
  font-style: normal;
}
